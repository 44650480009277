.song {
	box-sizing: border-box;
	position: relative;
	max-width: 24em;
	margin: 0 auto;
	text-align: left;
	color: #262626;
	background-color: #fff;
	border-radius: 0.75em;
	
	.inside {
		padding: 1.35em 1.75em 1.75em;
	}

	h2 {
		font-size: inherit;
		line-height: 1.5em;
		margin: 0;
	}

	.player {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 1.5em;
	}

	img {
		width: 5em;
		height: 100%;
		border-radius: 0.5em;
	}

	audio {
		outline: none;
		max-width: 14em;
		background: #262626;
	}

	audio::-webkit-media-controls-panel,
	audio::-webkit-media-controls {
		background: #fff;
	}

	.meta {
		font-size: 0.75em;
		font-weight: 800;
		line-height: 1.5;
		color: rgba(255, 255, 255, 0.9);

		padding: 1.5em 2.25em;
		background: rgba(0, 0, 0, 0.9);

		border-bottom-left-radius: 0.75em;
		border-bottom-right-radius: 0.75em;
	}
}




// .song-enter-active,
// .song-exit-active {
// 	transition: all 0.15s;
// }

// .song-enter {
// 	opacity: 0;
// 	transform: scale(0.85);
// }

// .song-enter-active {
// 	opacity: 1;
// 	transform: translateX(0);
// }

// .song-exit {
// 	opacity: 1;
// }

// .song-exit-active {
// 	opacity: 0;
// 	transform: scale(0.85);
// }
