// .TunesApp {
	
// 	@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,800');

// 	body {
// 		font-size: 16px;
// 		color: white;
// 		background-color: #282c34;
// 	}

// 	a {
// 		color: #61dafb;
// 		outline: none;
// 	}

// 	h1 {
// 		// color: #61dafb;

// 		font: {
// 			size: 2.25em;
// 			weight: 800;
// 			style: normal;
// 		}

// 		text-align: center;
// 		letter-spacing: -1px;
// 	}

// 	h1:first-child {
// 		margin-top: 0;
// 	}
// }

.App {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	// align-items: center;
	width: 100%;
	min-height: 100vh;
	text-align: center;
	background: black;
	animation-name: fade-in;
	animation-fill-mode: both;
	animation-duration: 1s;
	font: {
		size: 1em;
		family: 'Nunito Sans', helvetica, arial, sans-serif;
		weight: 600;
	}
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	header {
		margin: 3.5em 0 1.5em;
		background-color: black;
		border-bottom: 2px solid rgba(255, 255, 255, 0.1);
	}
	article {
		text-align: left;
	}
}
@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }

.content {
	align-self: center;
	max-width: 1200px;
	width: 85%;
	font-size: 1.15em;
	line-height: 1.5em;
	padding: 1.5em;
	background-color: black;
}
// @media screen and (min-width: 425) {
//     .App {
// 		font-size: 1.2em;
// 	}
//   }
//   @media screen and (${theme.sm_min_768}) {
//     // max-width: 800px;
//     // text-align: center;
//   }
//   @media screen and (${theme.md_min_1024}) {
//     // max-width: 1200px;
//     // text-align: center;
//   }
//   @media screen and (${theme.lg_min_1200}) {
//   }

