.search {
	box-sizing: border-box;
	width: 100%;
	display: block;
	padding: {
		top: 0.5em;
		bottom: 0.5em;
		left: 2.95em;
		right: 0.75em;
	}
	font-size: 1.25em;
	font-weight: 700;

	border: none;
	border-radius: 0.5em;
	background: white;
	color: #333;
	background: {
		image: url('../../assets/search.svg');
		repeat: no-repeat;
		position-y: 50%;
		position-x: 1em;
		color: white;
		size: 1em;
	}
}

