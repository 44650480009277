.about {
	color: white;
    span {
        color: #61dafb;
        &:hover {
            color:rgb(120, 81, 169)
        }
    } 
    h1{
        margin: 1.25em 0 1.5em;
    }
    
}